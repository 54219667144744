/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { AuthStateResponseInternalFromJSON, InfoFromJSON, LoginRequestToJSON, OtpLoginRequestToJSON, SsoProviderDescriptionFromJSON, StartSsoResponseParamsFromJSON, TargetSnapshotFromJSON, } from '../models';
/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
    /**
     */
    async approveAuthRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling approveAuth.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/auth/state/{id}/approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthStateResponseInternalFromJSON(jsonValue));
    }
    /**
     */
    async approveAuth(requestParameters, initOverrides) {
        const response = await this.approveAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getAuthStateRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getAuthState.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/auth/state/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthStateResponseInternalFromJSON(jsonValue));
    }
    /**
     */
    async getAuthState(requestParameters, initOverrides) {
        const response = await this.getAuthStateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getDefaultAuthStateRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/auth/state`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthStateResponseInternalFromJSON(jsonValue));
    }
    /**
     */
    async getDefaultAuthState(initOverrides) {
        const response = await this.getDefaultAuthStateRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getInfoRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => InfoFromJSON(jsonValue));
    }
    /**
     */
    async getInfo(initOverrides) {
        const response = await this.getInfoRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getSsoProvidersRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/sso/providers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SsoProviderDescriptionFromJSON));
    }
    /**
     */
    async getSsoProviders(initOverrides) {
        const response = await this.getSsoProvidersRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getTargetsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/targets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TargetSnapshotFromJSON));
    }
    /**
     */
    async getTargets(initOverrides) {
        const response = await this.getTargetsRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async loginRaw(requestParameters, initOverrides) {
        if (requestParameters.loginRequest === null || requestParameters.loginRequest === undefined) {
            throw new runtime.RequiredError('loginRequest', 'Required parameter requestParameters.loginRequest was null or undefined when calling login.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async login(requestParameters, initOverrides) {
        await this.loginRaw(requestParameters, initOverrides);
    }
    /**
     */
    async logoutRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async logout(initOverrides) {
        await this.logoutRaw(initOverrides);
    }
    /**
     */
    async otpLoginRaw(requestParameters, initOverrides) {
        if (requestParameters.otpLoginRequest === null || requestParameters.otpLoginRequest === undefined) {
            throw new runtime.RequiredError('otpLoginRequest', 'Required parameter requestParameters.otpLoginRequest was null or undefined when calling otpLogin.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/auth/otp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OtpLoginRequestToJSON(requestParameters.otpLoginRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async otpLogin(requestParameters, initOverrides) {
        await this.otpLoginRaw(requestParameters, initOverrides);
    }
    /**
     */
    async rejectAuthRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling rejectAuth.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/auth/state/{id}/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthStateResponseInternalFromJSON(jsonValue));
    }
    /**
     */
    async rejectAuth(requestParameters, initOverrides) {
        const response = await this.rejectAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async returnToSsoRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/sso/return`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async returnToSso(requestParameters = {}, initOverrides) {
        await this.returnToSsoRaw(requestParameters, initOverrides);
    }
    /**
     */
    async startSsoRaw(requestParameters, initOverrides) {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name', 'Required parameter requestParameters.name was null or undefined when calling startSso.');
        }
        const queryParameters = {};
        if (requestParameters.next !== undefined) {
            queryParameters['next'] = requestParameters.next;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/sso/providers/{name}/start`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => StartSsoResponseParamsFromJSON(jsonValue));
    }
    /**
     */
    async startSso(requestParameters, initOverrides) {
        const response = await this.startSsoRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
