/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { PortsInfoFromJSON, PortsInfoToJSON, } from './PortsInfo';
export function InfoFromJSON(json) {
    return InfoFromJSONTyped(json, false);
}
export function InfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'version': json['version'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'selectedTarget': !exists(json, 'selected_target') ? undefined : json['selected_target'],
        'externalHost': !exists(json, 'external_host') ? undefined : json['external_host'],
        'ports': PortsInfoFromJSON(json['ports']),
        'authorizedViaTicket': json['authorized_via_ticket'],
    };
}
export function InfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'version': value.version,
        'username': value.username,
        'selected_target': value.selectedTarget,
        'external_host': value.externalHost,
        'ports': PortsInfoToJSON(value.ports),
        'authorized_via_ticket': value.authorizedViaTicket,
    };
}
