/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiAuthState;
(function (ApiAuthState) {
    ApiAuthState["NotStarted"] = "NotStarted";
    ApiAuthState["Failed"] = "Failed";
    ApiAuthState["PasswordNeeded"] = "PasswordNeeded";
    ApiAuthState["OtpNeeded"] = "OtpNeeded";
    ApiAuthState["SsoNeeded"] = "SsoNeeded";
    ApiAuthState["WebUserApprovalNeeded"] = "WebUserApprovalNeeded";
    ApiAuthState["PublicKeyNeeded"] = "PublicKeyNeeded";
    ApiAuthState["Success"] = "Success";
})(ApiAuthState || (ApiAuthState = {}));
export function ApiAuthStateFromJSON(json) {
    return ApiAuthStateFromJSONTyped(json, false);
}
export function ApiAuthStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiAuthStateToJSON(value) {
    return value;
}
